import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {
  contentVisualPreviewEmptyImageElement,
  contentVisualPreviewImageElement,
} from '@cohort/wallet/apps/utils/contents';
import {Link} from '@phosphor-icons/react';

const CohortLinkVisualPreview: React.FC<VisualPreviewProps<CohortLinkMediaStruct>> = ({
  config,
  title,
}) => {
  const imageSrc = config.imageSrc;
  return imageSrc
    ? contentVisualPreviewImageElement(title, imageSrc)
    : contentVisualPreviewEmptyImageElement(<Link />);
};

export default CohortLinkVisualPreview;
