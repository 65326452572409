// i18nOwl-ignore [app-spotify.userDataPolicy]
import type {SpotifyAppStruct} from '@cohort/shared/apps/spotify';
import {SpotifyAppSpec} from '@cohort/shared/apps/spotify';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {SpotifyPlaylistContentMedia} from '@cohort/wallet/apps/spotify/medias/playlist/Media';
import {SpotifyFollowPlaylistTriggerIntegration} from '@cohort/wallet/apps/spotify/triggers/followPlaylist/TriggerIntegration';
import {SpotifyFollowUserTriggerIntegration} from '@cohort/wallet/apps/spotify/triggers/followUser/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const SpotifyApp: App<SpotifyAppStruct> = {
  spec: SpotifyAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    SpotifyFollowPlaylistTriggerIntegration,
    SpotifyFollowUserTriggerIntegration,
  ] as Array<TriggerIntegration>,
  medias: [SpotifyPlaylistContentMedia] as Array<ContentMedia>,
  logo: <SvgAppIcon name="spotify" height={40} width={40} />,
};

export default SpotifyApp;
