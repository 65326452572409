import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';
import {CohortImageMediaSpec} from '@cohort/shared/apps/cohort/medias/image';
import CohortImageDisplayComponent from '@cohort/wallet/apps/cohort/medias/image/DisplayComponent';
import CohortImageVisualPreview from '@cohort/wallet/apps/cohort/medias/image/VisualPreview';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {Image} from '@phosphor-icons/react';

export const CohortImageContentMedia: ContentMedia<CohortImageMediaStruct> = {
  spec: CohortImageMediaSpec,
  getTitle: t => t('medias.image.title', {ns: 'app-cohort'}),
  displayComponent: CohortImageDisplayComponent,
  icon: ({size, textColor}) => <Image size={size} className={textColor} />,
  visualPreview: CohortImageVisualPreview,
};
