import SpaceContextProvider from '@cohort/wallet/components/providers/SpaceContextProvider';
import ChallengeContextWrapper from '@cohort/wallet/layouts/challenges/ChallengeContextWrapper';
import {getMerchantSpaceRoute} from '@cohort/wallet/lib/Pages';
import {Navigate} from 'react-router-dom';

const SpaceController = {
  protectedRoutes: [
    {
      element: <SpaceContextProvider />,
      children: [
        {index: true, element: <Navigate to={getMerchantSpaceRoute()} replace />},
        {
          path: 'home',
          lazy: () => import('@cohort/wallet/pages/space/home/page'),
        },
        {
          path: 'badges',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/badges/page'),
            },
          ],
        },
        {
          path: 'challenges',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/challenges/overview/page'),
            },
            {
              path: ':challengeSlug',
              element: <ChallengeContextWrapper />,
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/space/challenges/challenge/page'),
                },
              ],
            },
          ],
        },
        {
          path: 'contents',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/contents/overview/page'),
            },
          ],
        },
        {
          path: 'rewards',
          children: [
            {
              index: true,
              lazy: () => import('@cohort/wallet/pages/space/rewards/overview/page'),
            },
            {
              path: 'perks/:perkId',
              children: [
                {
                  index: true,
                  lazy: () => import('@cohort/wallet/pages/space/rewards/perk/page'),
                },
                {
                  path: 'perk-accesses/:perkAccessId/use',
                  lazy: () => import('@cohort/wallet/pages/space/rewards/perk/usage/page'),
                },
              ],
            },
            {
              path: 'ownerships',
              children: [
                {
                  path: 'imports/new',
                  lazy: () => import('@cohort/wallet/pages/space/ownerships/import/page'),
                },
                {
                  path: ':ownershipId',
                  children: [
                    {
                      index: true,
                      lazy: () => import('@cohort/wallet/pages/space/ownerships/ownership/page'),
                    },
                    {
                      path: 'transfer/new',
                      lazy: () =>
                        import('@cohort/wallet/pages/space/ownerships/ownership/transfer/page'),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'settings',
          lazy: () => import('@cohort/wallet/pages/space/settings/page'),
        },
      ],
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/space/login/page'),
    },
    {
      path: 'invitations/:invitationId/login',
      lazy: () => import('@cohort/wallet/pages/space/invitations/login/page'),
    },
  ],
  bothPublicAndProtectedRoutes: [
    {
      path: 'ownerships/:ownershipId/confirm-transfer',
      lazy: () => import('@cohort/wallet/pages/space/ownerships/ownership/confirm-transfer/page'),
    },
    {
      path: 'invitations/:invitationId',
      lazy: () => import('@cohort/wallet/pages/space/invitations/invitation/page'),
    },
    {
      path: 'challenges/:challengeSlug',
      element: <ChallengeContextWrapper />,
      children: [
        {
          path: 'steps/:stepId/verify',
          lazy: () => import('@cohort/wallet/pages/challenges/steps/verify/page'),
        },
      ],
    },
  ],
};

export default SpaceController;
