import type {TikTokCommentTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import {TikTokCommentTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import TikTokCommentTiktokTriggerActionCtaComponent from '@cohort/wallet/apps/tiktok/triggers/commentTiktok/ActionCtaComponent';
import TiktokCommentTiktokTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/tiktok/triggers/commentTiktok/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const TikTokCommentTiktokTriggerIntegration: TriggerIntegration<TikTokCommentTiktokTriggerStruct> =
  {
    type: 'action',
    spec: TikTokCommentTiktokTriggerSpec,
    detailsComponent: TiktokCommentTiktokTriggerIntegrationDetailsComponent,
    actionCtaComponent: TikTokCommentTiktokTriggerActionCtaComponent,
  };
