import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {contentVisualPreviewEmptyImageElement} from '@cohort/wallet/apps/utils/contents';
import {ArticleNyTimes} from '@phosphor-icons/react';

const CohortArticleVisualPreview: React.FC<VisualPreviewProps<CohortArticleMediaStruct>> = ({
  config,
  title,
}) => {
  return contentVisualPreviewEmptyImageElement(<ArticleNyTimes />);
};

export default CohortArticleVisualPreview;
