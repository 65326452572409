import type {GoogleFitCompleteActivityTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import GoogleFitActivityGroups from '@cohort/wallet/apps/google-fit/triggerIntegrations/ActivityGroups';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {FitnessCompleteActivityTriggerIntegrationDetailComponent} from '@cohort/wallet/components/challenges/FitnessDetailComponents';

const GoogleFitCompleteActivityTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<GoogleFitCompleteActivityTriggerStruct>
> = ({config}) => (
  <FitnessCompleteActivityTriggerIntegrationDetailComponent
    ActivityGroupsComponent={GoogleFitActivityGroups}
    config={config}
  />
);

export default GoogleFitCompleteActivityTriggerIntegrationDetailComponent;
