import type {StravaCompleteActivityTriggerStruct} from '@cohort/shared/apps/strava/triggers/completeActivity';
import StravaActivityGroups from '@cohort/wallet/apps/strava/triggerIntegrations/ActivityGroups';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {FitnessCompleteActivityTriggerIntegrationDetailComponent} from '@cohort/wallet/components/challenges/FitnessDetailComponents';

const StravaCompleteActivityTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<StravaCompleteActivityTriggerStruct>
> = ({config}) => (
  <FitnessCompleteActivityTriggerIntegrationDetailComponent
    ActivityGroupsComponent={StravaActivityGroups}
    config={config}
  />
);

export default StravaCompleteActivityTriggerIntegrationDetailComponent;
