import type {StravaBeActiveTriggerStruct} from '@cohort/shared/apps/strava/triggers/beActive';
import {StravaBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/strava/triggers/beActive';
import StravaBeActiveTriggerIntegrationDetailComponent from '@cohort/wallet/apps/strava/triggerIntegrations/beActive/DetailComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const StravaBeActiveTriggerIntegration: TriggerIntegration<StravaBeActiveTriggerStruct> = {
  type: 'verification',
  spec: StravaBeActiveTriggerIntegrationSpec,
  detailsComponent: StravaBeActiveTriggerIntegrationDetailComponent,
  disableExponentialBackoff: true,
};
