import {cn} from '@cohort/shared-frontend/utils/classNames';

export type FieldWrapperProps = {
  children: React.ReactNode;
  error?: string | JSX.Element;
  className?: string;
  label?: string;
  mandatory?: boolean;
  labelPosition?: 'top' | 'left' | 'right';
};
const FieldWrapper: React.FC<FieldWrapperProps> = ({
  children,
  className,
  error,
  label,
  mandatory,
  labelPosition = 'top',
}) => (
  <div
    className={cn(
      'flex w-full',
      labelPosition === 'top' ? 'flex-col gap-2' : 'items-center gap-4',
      className
    )}
  >
    {label && (
      <label className={cn(labelPosition === 'right' && 'order-last')}>
        {label} {mandatory && <span className="text-red-500">*</span>}
      </label>
    )}
    <div className="flex flex-col">
      {children}
      {error}
    </div>
  </div>
);

export default FieldWrapper;
