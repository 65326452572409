const StoreController = {
  protectedRoutes: [
    {
      path: 'checkout',
      lazy: () => import('@cohort/wallet/pages/store/checkout/page'),
    },
    {
      path: 'orders/:orderId',
      lazy: () => import('@cohort/wallet/pages/store/orders/order/page'),
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/store/login/page'),
    },
  ],
};

export default StoreController;
