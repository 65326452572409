import type {GoogleFitCompleteActivityTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import {GoogleFitCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import GoogleFitCompleteActivityTriggerIntegrationDetailComponent from '@cohort/wallet/apps/google-fit/triggerIntegrations/completeActivity/DetailComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const GoogleFitCompleteActivityTriggerIntegration: TriggerIntegration<GoogleFitCompleteActivityTriggerStruct> =
  {
    type: 'verification',
    spec: GoogleFitCompleteActivityTriggerIntegrationSpec,
    detailsComponent: GoogleFitCompleteActivityTriggerIntegrationDetailComponent,
    disableExponentialBackoff: true,
  };
