import type {StravaAppStruct} from '@cohort/shared/apps/strava';
import {StravaAppSpec} from '@cohort/shared/apps/strava';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {StravaBeActiveTriggerIntegration} from '@cohort/wallet/apps/strava/triggerIntegrations/beActive/TriggerIntegration';
import {StravaCompleteActivityTriggerIntegration} from '@cohort/wallet/apps/strava/triggerIntegrations/completeActivity/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const StravaApp: App<StravaAppStruct> = {
  spec: StravaAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    StravaBeActiveTriggerIntegration,
    StravaCompleteActivityTriggerIntegration,
  ] as Array<TriggerIntegration>,
  medias: [] as Array<ContentMedia>,
  logo: <SvgAppIcon name="strava" height={40} width={40} />,
};

export default StravaApp;
