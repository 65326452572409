import i18n from '@cohort/components-xps/lib/i18n';
import type {GoogleFitIntegrationActivityGroupsType} from '@cohort/shared/apps/google-fit/activity';
import {
  getGoogleFitActivityTypeLabel,
  GoogleFitIntegrationActivityTypesMappedByGroups,
} from '@cohort/shared/apps/google-fit/activity';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {ActivityLabel} from '@cohort/wallet/components/challenges/FitnessDetailComponents';

const GoogleFitActivityGroups: React.FC<{activityGroups: string[]}> = ({activityGroups}) => {
  const currentLanguage = formatI18nLanguage(i18n.language);

  return activityGroups
    .flatMap(
      group =>
        GoogleFitIntegrationActivityTypesMappedByGroups[
          group as GoogleFitIntegrationActivityGroupsType
        ]
    )
    .map(activityType => (
      <ActivityLabel key={`google-fit-${activityType}`}>
        {getGoogleFitActivityTypeLabel(activityType, currentLanguage)}
      </ActivityLabel>
    ));
};

export default GoogleFitActivityGroups;
