import type {LocalizationConfig} from '@cohort/shared/schema/common';
import {EmailSchema} from '@cohort/shared/schema/common';
import type {
  CohortFormAnswer,
  CohortFormConfig,
  CohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormAnswerSchema} from '@cohort/shared/schema/common/cohortForm';
import {
  buildLocalizationConfig,
  formatI18nLanguage,
  getLocalizedContentOrThrow,
} from '@cohort/shared/utils/localization';
import RichText from '@cohort/shared-frontend/components/RichText';
import Button from '@cohort/wallet/components/button/Button';
import {CheckboxInput} from '@cohort/wallet/components/forms/CheckboxInput';
import {Input} from '@cohort/wallet/components/forms/Input';
import {ScoreInput} from '@cohort/wallet/components/forms/ScoreInput';
import {SelectPicker} from '@cohort/wallet/components/forms/SelectPicker';
import {TextArea} from '@cohort/wallet/components/forms/TextArea';
import {Transition} from '@cohort/wallet/components/transitions/Transition';
import {useListUserAttributes} from '@cohort/wallet/hooks/api/UserAttributes';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import type {UserAttributeWDto} from '@cohort/wallet-schemas/userAttributes';
import dayjs from 'dayjs';
import i18n from 'i18next';
import React, {Fragment, useCallback, useState} from 'react';
import type {Control, UseFormRegister, UseFormWatch} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {compact, uniq} from 'remeda';
import {match} from 'ts-pattern';
import {z} from 'zod';

type PromptInputProps = {
  prompt: CohortFormPrompt;
  localizationConfig: LocalizationConfig;
  formErrors: Record<string, string>;
  setValue: (name: string, value: string | string[]) => void;
  register: UseFormRegister<Record<string, CohortFormAnswer>>;
  control: Control<Record<string, CohortFormAnswer>>;
  watch: UseFormWatch<Record<string, CohortFormAnswer>>;
};

const PromptInput: React.FC<PromptInputProps> = ({
  prompt,
  localizationConfig,
  formErrors,
  setValue,
  register,
  control,
  watch,
}) => {
  const localizedName = getLocalizedContentOrThrow(prompt.name, localizationConfig);

  const renderError = useCallback(
    (promptId: string): JSX.Element => {
      if (formErrors[promptId] === undefined) {
        return <Fragment />;
      }

      return (
        <Transition appear enterFrom="bottom" show twClasses="text-red-500 mt-1">
          <p>{formErrors[promptId]}</p>
        </Transition>
      );
    },
    [formErrors]
  );

  const value = watch(prompt.id);
  const error = renderError(prompt.id);

  return match(prompt.type)
    .with('text', () => (
      <Input
        type="text"
        label={localizedName}
        mandatory={prompt.mandatory}
        error={error}
        register={register(prompt.id)}
      />
    ))
    .with('date', () => (
      <Input
        type="date"
        label={localizedName}
        mandatory={prompt.mandatory}
        error={renderError(prompt.id)}
        register={register(prompt.id, {
          setValueAs: (value: unknown) => {
            const parsed = z.coerce.date().safeParse(value);
            if (!parsed.success) {
              return null;
            }
            return parsed.data;
          },
        })}
        value={value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value?.toString()}
      />
    ))
    .with('number', () => (
      <Input
        type="number"
        label={localizedName}
        mandatory={prompt.mandatory}
        error={error}
        register={register(prompt.id, {
          setValueAs: (value: string) => {
            const number = parseFloat(value);
            return isNaN(number) ? null : number;
          },
        })}
      />
    ))
    .with('select', () => {
      const localizedOptions = prompt.options?.map(option => {
        return {
          value: option.value,
          label: getLocalizedContentOrThrow(option.label, localizationConfig),
        };
      });
      if (localizedOptions === undefined) {
        throw new Error('No options for select');
      }

      if (prompt.multipleChoice) {
        if (value !== null && !Array.isArray(value)) {
          setValue(prompt.id, []);
          return <Fragment />;
        }
        return (
          <SelectPicker
            label={localizedName}
            mandatory={prompt.mandatory}
            error={error}
            value={localizedOptions.filter(option => value?.includes(option.value))}
            options={localizedOptions}
            onChange={newValue =>
              setValue(
                prompt.id,
                newValue.map(option => option.value)
              )
            }
            isMulti={true}
          />
        );
      }
      return (
        <SelectPicker
          label={localizedName}
          mandatory={prompt.mandatory}
          error={error}
          value={localizedOptions.find(option => option.value === value)}
          options={localizedOptions}
          onChange={newValue => setValue(prompt.id, newValue?.value ?? '')}
        />
      );
    })
    .with('checkbox', () => (
      <CheckboxInput
        label={localizedName}
        mandatory={prompt.mandatory}
        error={error}
        register={register(prompt.id)}
      />
    ))
    .with('email', () => (
      <Input
        type="email"
        label={localizedName}
        mandatory={prompt.mandatory}
        error={error}
        register={register(prompt.id)}
      />
    ))
    .with('long-text', () => (
      <TextArea
        label={localizedName}
        mandatory={prompt.mandatory}
        error={error}
        register={register(prompt.id)}
      />
    ))
    .with('score', () => (
      <ScoreInput
        label={localizedName}
        mandatory={prompt.mandatory}
        error={error}
        name={prompt.id}
        control={control}
      />
    ))
    .exhaustive();
};

type WrappedCohortFormComponentProps = {
  config: CohortFormConfig;
  onSubmit: (values: Record<string, CohortFormAnswer>) => void;
  isLoading: boolean;
  onSubmitTracking: TrackingConfig;
  userAttributes: Array<UserAttributeWDto>;
};

const WrappedCohortFormComponent: React.FC<WrappedCohortFormComponentProps> = ({
  config,
  onSubmit,
  isLoading,
  onSubmitTracking,
  userAttributes,
}) => {
  const merchant = useMerchantContext();
  const {t} = useTranslation('components', {keyPrefix: 'forms.cohortForm'});
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  // include user attributes in default values
  const defaultValues: Record<string, CohortFormAnswer> = {};
  for (const prompt of config.prompts) {
    const userAttributeValue = userAttributes.find(
      attribute => attribute.userPropertyId === prompt.userPropertyId
    )?.value;

    const answerFromUserAttribute = userAttributeValue
      ? CohortFormAnswerSchema.parse(userAttributeValue)
      : null;
    defaultValues[prompt.id] = answerFromUserAttribute ?? '';

    if (prompt.type === 'checkbox') {
      defaultValues[prompt.id] = false;
    }

    if (prompt.type === 'select' && prompt.multipleChoice) {
      defaultValues[prompt.id] = [];
    }
  }

  const {
    register,
    handleSubmit: onFormSubmit,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues,
  });

  const localizationConfig = buildLocalizationConfig(
    formatI18nLanguage(i18n.language),
    merchant.supportedLanguages,
    merchant.defaultLanguage
  );

  const validateForm = (data: Record<string, CohortFormAnswer>): void => {
    const values: Record<string, CohortFormAnswer> = {};
    const submitErrors: Record<string, string> = {};
    for (const prompt of config.prompts) {
      values[prompt.id] = data[prompt.id] ?? '';
      const answer = data[prompt.id];
      const emptyAnswer =
        answer === '' ||
        answer === undefined ||
        answer === null ||
        (Array.isArray(answer) && answer.length === 0);

      if (prompt.mandatory && emptyAnswer) {
        submitErrors[prompt.id] = t('required');
      }

      if (prompt.type === 'email' && !emptyAnswer) {
        try {
          EmailSchema.parse(data[prompt.id] ?? '');
        } catch {
          submitErrors[prompt.id] = t('invalidEmail');
        }
      }

      if (prompt.type === 'checkbox' && prompt.mandatory && emptyAnswer) {
        submitErrors[prompt.id] = t('checkboxRequired');
      }
    }

    if (Object.keys(submitErrors).length > 0) {
      setFormErrors(submitErrors);
      return;
    }
    onSubmit(values);
  };

  return (
    <div className="flex w-full flex-col items-center gap-8">
      <div className="w-full group-[.embedded]:max-w-3xl">
        <form className="group-[.embedded]:max-w-3xl">
          <div className="flex flex-col gap-4">
            <RichText html={getLocalizedContentOrThrow(config.description, localizationConfig)} />
            {config.prompts.map(prompt => (
              <PromptInput
                prompt={prompt}
                localizationConfig={localizationConfig}
                key={prompt.id}
                formErrors={formErrors}
                setValue={setValue}
                register={register}
                watch={watch}
                control={control}
              />
            ))}
          </div>
        </form>
      </div>
      <Button
        variant="primary"
        size="small"
        testId="perk-form-submit"
        className="w-full"
        onClick={onFormSubmit(validateForm)}
        loading={isLoading}
        tracking={onSubmitTracking}
      >
        {t('submit')}
      </Button>
    </div>
  );
};

type CohortFormComponentProps = {
  config: CohortFormConfig;
  onSubmit: (values: Record<string, CohortFormAnswer>) => void;
  isLoading: boolean;
  onSubmitTracking: TrackingConfig;
};

const CohortFormComponent: React.FC<CohortFormComponentProps> = ({
  config,
  onSubmit,
  isLoading,
  onSubmitTracking,
}) => {
  const userPropertyIds = uniq(compact(config.prompts.map(prompt => prompt.userPropertyId)));
  const {data: userAttributes} = useListUserAttributes(userPropertyIds);

  if (userAttributes === undefined) {
    return <Fragment />;
  }

  // Refresh the form at user attributes change
  const refreshKey = userAttributes.map(attribute => attribute.value).join();

  return (
    <WrappedCohortFormComponent
      key={refreshKey}
      config={config}
      onSubmit={onSubmit}
      isLoading={isLoading}
      onSubmitTracking={onSubmitTracking}
      userAttributes={userAttributes}
    />
  );
};
export default CohortFormComponent;
