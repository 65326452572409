import type {CohortDigitalAssetOwnedTriggerStruct} from '@cohort/shared/apps/cohort/triggers/digitalAssetOwned';
import {CohortDigitalAssetOwnedTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/digitalAssetOwned';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortDigitalAssetOwnedTriggerIntegration: TriggerIntegration<CohortDigitalAssetOwnedTriggerStruct> =
  {
    type: 'verification',
    spec: CohortDigitalAssetOwnedTriggerIntegrationSpec,
    disableExponentialBackoff: true,
  };
