import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import {TwitterFollowTriggerIntegrationSpec} from '@cohort/shared/apps/twitter/triggers/follow';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TwitterFollowTriggerActionCtaComponent from '@cohort/wallet/apps/twitter/triggerIntegrations/follow/ActionCtaComponent';

export const TwitterFollowTriggerIntegration: TriggerIntegration<TwitterFollowTriggerStruct> = {
  type: 'action',
  spec: TwitterFollowTriggerIntegrationSpec,
  actionCtaComponent: TwitterFollowTriggerActionCtaComponent,
};
