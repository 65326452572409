import {FACEBOOK_URL} from '@cohort/shared/apps/facebook/common';
import type {FacebookLikePageUnverifiedTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePageUnverified';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaUnverified from '@cohort/wallet/components/challenges/OpenCtaUnverified';
import React from 'react';
import {useTranslation} from 'react-i18next';

const FacebookLikePageUnverifiedTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<FacebookLikePageUnverifiedTriggerStruct>
> = ({config, processStepVerification, trackingMetadata}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggerIntegrations.like-page-unverified',
  });

  const url = `${FACEBOOK_URL}/${config.pageId}`;

  return (
    <OpenCtaUnverified
      url={url}
      ctaTitle={t('ctaTitle')}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
    />
  );
};

export default FacebookLikePageUnverifiedTriggerActionCtaComponent;
