type EmbedPostProps = {
  videoId: string;
  width?: number;
};

const TiktokEmbedPost: React.FC<EmbedPostProps> = ({videoId, width = 400}) => (
  <iframe
    src={`https://www.tiktok.com/player/v1/${videoId}?music_info=1&description=1&rel=0`}
    style={{width: width, height: width * 1.33}}
  />
);

export default TiktokEmbedPost;
