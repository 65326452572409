import type {InstagramAppStruct} from '@cohort/shared/apps/instagram';
import {InstagramAppSpec} from '@cohort/shared/apps/instagram';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {InstagramCloseFriendsListPerkIntegration} from '@cohort/wallet/apps/instagram/perkIntegrations/closeFriendsList/PerkIntegration';
import {InstagramCommentMediaTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/commentMedia/TriggerIntegration';
import {InstagramFollowTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/follow/TriggerIntegration';
import {InstagramLikeMediaTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/likeMedia/TriggerIntegration';
import {InstagramTagInPostTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/tagInPost/TriggerIntegration';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const InstagramApp: App<InstagramAppStruct> = {
  spec: InstagramAppSpec,
  perkIntegrations: [InstagramCloseFriendsListPerkIntegration],
  triggerIntegrations: [
    InstagramFollowTriggerIntegration,
    InstagramCommentMediaTriggerIntegration,
    InstagramLikeMediaTriggerIntegration,
    InstagramTagInPostTriggerIntegration,
  ] as Array<TriggerIntegration>,
  medias: [] as Array<ContentMedia>,
  logo: <SvgAppIcon name="instagram" height={40} width={40} />,
};

export default InstagramApp;
