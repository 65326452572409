import Title from '@cohort/components-xps/components/Title';
import type {ConnectorId} from '@cohort/shared/apps';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {App} from '@cohort/wallet/apps';
import type {ButtonBaseProps} from '@cohort/wallet/components/button/Button';
import Button from '@cohort/wallet/components/button/Button';
import BaseModal from '@cohort/wallet/components/modals/BaseModal';
import DrawerModal from '@cohort/wallet/components/modals/DrawerModal';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {ConnectionsApi} from '@cohort/wallet/lib/Endpoints';
import {Info} from '@phosphor-icons/react';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {shallow} from 'zustand/shallow';

type OAuthConnectButtonProps = Omit<ButtonBaseProps, 'loading' | 'onClick'> & {
  app: App;
  connectorId: ConnectorId;
  xpsRedirectUrl: string;
  existingConnectionId?: string;
  icon?: JSX.Element;
  userDataPolicyPosition?: 'left' | 'right';
};

type DataPolicyModalProps = {
  app: App;
  onClose: () => void;
};

const DataPolicyModal: React.FC<DataPolicyModalProps> = ({app, onClose}) => {
  const {accentColor} = useThemeContext();
  const {isMobile} = useScreenSize();
  const {t} = useTranslation('apps', {keyPrefix: 'oauthConnectButton'});

  const Modal = isMobile ? DrawerModal : BaseModal;
  const ModalContent = (
    <div className="md:max-w-[50vw]">
      <p className="text-sm">
        <Trans
          i18nKey="userDataPolicy"
          ns={`app-${app.spec.id}`}
          components={{
            externalLink: (
              <a
                style={{color: accentColor}}
                className="cursor-pointer"
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      </p>
      <Button
        variant="secondary"
        className="mt-4 w-full"
        onClick={onClose}
        tracking={{namespace: 'apps.userDataPolicy.close'}}
      >
        {t('closeModal')}
      </Button>
    </div>
  );

  return (
    <Modal
      title={<Title>{t('userDataPolicyModalTitle')}</Title>}
      tracking={{namespace: 'modals.apps.userDataPolicy'}}
      onClose={onClose}
    >
      {ModalContent}
    </Modal>
  );
};

const OAuthConnectButton: React.FC<OAuthConnectButtonProps> = ({
  app,
  connectorId,
  xpsRedirectUrl,
  existingConnectionId,
  userDataPolicyPosition = 'left',
  ...props
}) => {
  const [dataPolicyModal, setDataPolicyModal] = useState(false);
  const getAuthorizationUrl = useApi(ConnectionsApi.getOauthAuthorizationUrl);
  const {embedUrl, embedded} = useAppStore(
    store => ({
      embedUrl: store.embedUrl,
      embedded: store.embedded,
    }),
    shallow
  );
  const {mutate: startConnectionFlow, isLoading} = useCohortMutation({
    mutationFn: async () =>
      getAuthorizationUrl({
        connectorId,
        embedUrl: embedUrl ?? null,
        xpsRedirectUrl,
        existingConnectionId,
      }),
    onSuccess: ({authorizationUrl}) =>
      embedded
        ? window.parent.location.replace(authorizationUrl)
        : window.location.replace(authorizationUrl),
  });

  return (
    <div>
      {dataPolicyModal && <DataPolicyModal app={app} onClose={() => setDataPolicyModal(false)} />}
      <div className="flex items-center gap-2">
        {app.withUserDataPolicy && (
          <Button
            variant="secondary"
            className={cn(
              props.className,
              '!w-auto !rounded-xl !px-2 !py-2',
              userDataPolicyPosition === 'left' ? 'order-first' : 'order-last'
            )}
            onClick={() => setDataPolicyModal(true)}
            tracking={{
              namespace: 'apps.userDataPolicy',
              metadata: {appId: app.spec.id},
            }}
          >
            {props.icon}
            <Info size={20} />
          </Button>
        )}
        <Button
          variant="primary"
          {...props}
          className={cn(
            props.className,
            userDataPolicyPosition === 'left' ? 'order-last' : 'order-first'
          )}
          loading={isLoading}
          onClick={() => startConnectionFlow()}
        >
          {props.icon}
          {props.text}
        </Button>
      </div>
    </div>
  );
};

export default OAuthConnectButton;
