import MediaContentCohortImage from '@cohort/components-xps/components/contents/apps/cohort/MediaContentCohortImage';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';

const CohortImageDisplayComponent: React.FC<MediaContentProps<CohortImageMediaStruct>> = ({
  description,
  config,
  title,
}) => {
  return <MediaContentCohortImage config={config} title={title} description={description} />;
};

export default CohortImageDisplayComponent;
