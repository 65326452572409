type DataAttributes = Record<string, string | number>;
export default function serializeDataAttributes(dataAttributes: DataAttributes): DataAttributes {
  const attrs: DataAttributes = {};

  Object.entries(dataAttributes).forEach(([key, value]) => {
    attrs[`data-${key}`] = value;
  });

  return attrs;
}
