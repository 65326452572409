import ChallengeContextProvider from '@cohort/wallet/components/providers/ChallengeContextProvider';
import {Outlet} from 'react-router-dom';

const ChallengeContextWrapper = (): JSX.Element => (
  <ChallengeContextProvider>
    <Outlet />
  </ChallengeContextProvider>
);

export default ChallengeContextWrapper;
