import {BadgeWSchema} from '@cohort/wallet-schemas/badge';
import {CampaignChallengeWSchema} from '@cohort/wallet-schemas/campaign';
import {OwnershipWSchema} from '@cohort/wallet-schemas/ownership';
import {PerkSpaceViewWSchema} from '@cohort/wallet-schemas/perkAccess';
import {z} from 'zod';

export const SpaceWSchema = z.object({
  badges: z.array(BadgeWSchema),
  challenges: z.array(CampaignChallengeWSchema),
  ownerships: z.array(OwnershipWSchema),
  perks: z.array(PerkSpaceViewWSchema),
});
export type SpaceWDto = z.infer<typeof SpaceWSchema>;

export const SyncSpaceResultWSchema = z.object({
  newOwnershipsFound: z.boolean(),
});
export type SyncSpaceResultWDto = z.infer<typeof SyncSpaceResultWSchema>;
