import type {YoutubeCommentTriggerStruct} from '@cohort/shared/apps/youtube/triggers/comment';
import {YoutubeCommentTriggerSpec} from '@cohort/shared/apps/youtube/triggers/comment';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import YoutubeCommentTriggerActionCtaComponent from '@cohort/wallet/apps/youtube/triggers/comment/ActionCtaComponent';
import YoutubeCommentTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/youtube/triggers/comment/DetailsComponent';

export const YoutubeCommentTriggerIntegration: TriggerIntegration<YoutubeCommentTriggerStruct> = {
  type: 'action',
  spec: YoutubeCommentTriggerSpec,
  detailsComponent: YoutubeCommentTriggerIntegrationDetailsComponent,
  actionCtaComponent: YoutubeCommentTriggerActionCtaComponent,
};
