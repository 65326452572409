import {cn} from '@cohort/shared-frontend/utils/classNames';
import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import React from 'react';
import type {UseFormRegisterReturn} from 'react-hook-form';

type DefaultInput = JSX.IntrinsicElements['input'];

export type InputProps = {
  width?: string;
  testId?: string;
  register?: UseFormRegisterReturn;
  label?: string;
  mandatory?: boolean;
  error?: JSX.Element;
};

export const Input: React.FC<DefaultInput & InputProps> = ({
  testId,
  register,
  className,
  label,
  mandatory,
  width,
  error,
  ...props
}) => (
  <FieldWrapper className={width} label={label} mandatory={mandatory} error={error}>
    <input
      data-testid={testId}
      {...props}
      {...register} // Register needs to be placed after inputProps to avoid overriding the input's properties.
      className={cn(
        'duration-50 block w-full rounded-md bg-[--xps-input-background-color] transition ease-in-out [border-color:--xps-input-border-color] focus:ring-[--xps-input-active-border-color] focus:[border-color:--xps-input-active-border-color] sm:text-sm',
        className
      )}
      style={{
        borderRadius: 'var(--xps-input-border-radius)',
      }}
    />
  </FieldWrapper>
);
