import MediaContentCohortVideo from '@cohort/components-xps/components/contents/apps/cohort/MediaContentCohortVideo';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';

const CohortVideoDisplayComponent: React.FC<MediaContentProps<CohortVideoMediaStruct>> = ({
  description,
  config,
  title,
}) => {
  return <MediaContentCohortVideo config={config} title={title} description={description} />;
};

export default CohortVideoDisplayComponent;
