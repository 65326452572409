import type {CohortUserPropertyConditionMetTriggerStruct} from '@cohort/shared/apps/cohort/triggers/userPropertyConditionMet';
import {CohortUserPropertyConditionMetTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/userPropertyConditionMet';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortUserPropertyConditionMetTriggerIntegration: TriggerIntegration<CohortUserPropertyConditionMetTriggerStruct> =
  {
    type: 'verification',
    spec: CohortUserPropertyConditionMetTriggerIntegrationSpec,
    disableExponentialBackoff: true,
  };
