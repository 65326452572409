import Tooltip from '@cohort/shared-frontend/components/Tooltip';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {ArrowsClockwise} from '@phosphor-icons/react';
import {useIsMutating} from '@tanstack/react-query';
import {useEffect, useRef} from 'react';

type OpenCtaWithRefreshProps = Pick<
  ActionCtaComponentProps,
  'processStepVerification' | 'trackingMetadata' | 'stepId'
> & {
  url: string;
  ctaTitle: string;
  refreshCtaTitle: string;
};

const OpenCtaWithRefresh: React.FC<OpenCtaWithRefreshProps> = ({
  url,
  stepId,
  processStepVerification,
  trackingMetadata,
  ctaTitle,
  refreshCtaTitle,
}) => {
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const {isMobile} = useScreenSize();
  const wasCtaClicked = useRef(false);
  const isVerifying = ongoingVerifications > 0;

  useEffect(() => {
    function handleWindowFocus(): void {
      if (wasCtaClicked.current) {
        wasCtaClicked.current = false;
        processStepVerification();
      }
    }

    window.addEventListener('focus', handleWindowFocus);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      wasCtaClicked.current = false;
    };
  }, [processStepVerification]);

  return (
    <div className={cn('flex w-full gap-2', isMobile && 'flex-col')}>
      <a
        href={url}
        target="_blank"
        className={cn('block w-full', isVerifying && 'pointer-events-none')}
        onClick={() => {
          wasCtaClicked.current = true;
        }}
      >
        <Button
          variant="primary"
          size="small"
          className="w-full"
          tracking={{namespace: 'challenges.detail.actionCta', metadata: trackingMetadata}}
          loading={isVerifying}
        >
          {ctaTitle}
        </Button>
      </a>
      <Button
        variant={isMobile ? 'link' : 'secondary'}
        size="small"
        className={cn(isMobile && 'text-[--xps-text-color]')}
        tracking={{namespace: 'challenges.detail.refreshCta', metadata: trackingMetadata}}
        onClick={processStepVerification}
        disabled={isVerifying}
      >
        {isMobile ? (
          refreshCtaTitle
        ) : (
          <Tooltip content={refreshCtaTitle}>
            <ArrowsClockwise size={20} />
          </Tooltip>
        )}
      </Button>
    </div>
  );
};

export default OpenCtaWithRefresh;
