import type {SpotifyFollowUserTriggerStruct} from '@cohort/shared/apps/spotify/triggers/followUser';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaWithRefresh from '@cohort/wallet/components/challenges/OpenCtaWithRefresh';
import {useTranslation} from 'react-i18next';

const SpotifyFollowUserTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<SpotifyFollowUserTriggerStruct>
> = ({config, trackingMetadata, stepId, processStepVerification}) => {
  const {t} = useTranslation('app-spotify', {
    keyPrefix: 'triggerIntegrations.follow-user',
  });

  return (
    <OpenCtaWithRefresh
      url={config.profileUrl}
      stepId={stepId}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
      ctaTitle={t('ctaTitle')}
      refreshCtaTitle={t('refreshTitle')}
    />
  );
};

export default SpotifyFollowUserTriggerActionCtaComponent;
