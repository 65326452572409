import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaWithRefresh from '@cohort/wallet/components/challenges/OpenCtaWithRefresh';
import {useTranslation} from 'react-i18next';

const InstagramCommentMediaTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<InstagramCommentMediaTriggerStruct>
> = ({config, trackingMetadata, stepId, processStepVerification}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'triggerIntegrations.comment-media',
  });

  return (
    <OpenCtaWithRefresh
      url={config.mediaPermalink}
      stepId={stepId}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
      ctaTitle={t('ctaTitle')}
      refreshCtaTitle={t('refreshTitle')}
    />
  );
};

export default InstagramCommentMediaTriggerActionCtaComponent;
