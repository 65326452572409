import type {SpotifyPlaylistMediaStruct} from '@cohort/shared/apps/spotify/medias/playlist';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {
  contentVisualPreviewEmptyImageElement,
  contentVisualPreviewImageElement,
} from '@cohort/wallet/apps/utils/contents';
import {SpotifyLogo} from '@phosphor-icons/react';

const SpotifyPlaylistVisualPreview: React.FC<VisualPreviewProps<SpotifyPlaylistMediaStruct>> = ({
  config,
  title,
}) => {
  const playlistThumbnailUrl = config.playlistThumbnailUrl;
  return playlistThumbnailUrl
    ? contentVisualPreviewImageElement(title, playlistThumbnailUrl)
    : contentVisualPreviewEmptyImageElement(<SpotifyLogo />);
};

export default SpotifyPlaylistVisualPreview;
