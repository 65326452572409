import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentYoutubeVideo from '@cohort/components-xps/components/contents/apps/youtube/MediaContentYoutubeVideo';
import type {YoutubeVideoMediaStruct} from '@cohort/shared/apps/youtube/medias/video';

const YoutubeVideoDisplayComponent: React.FC<MediaContentProps<YoutubeVideoMediaStruct>> = ({
  config,
  description,
  title,
}) => {
  return <MediaContentYoutubeVideo config={config} title={title} description={description} />;
};

export default YoutubeVideoDisplayComponent;
