import i18n from '@cohort/components-xps/lib/i18n';
import type {StravaTriggerIntegrationActivityGroupsType} from '@cohort/shared/apps/strava/activity';
import {
  getStravaActivityTypeLabel,
  StravaTriggerIntegrationActivityTypesMappedByGroups,
} from '@cohort/shared/apps/strava/activity';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {ActivityLabel} from '@cohort/wallet/components/challenges/FitnessDetailComponents';

const StravaActivityGroups: React.FC<{activityGroups: string[]}> = ({activityGroups}) => {
  const currentLanguage = formatI18nLanguage(i18n.language);

  return activityGroups
    .flatMap(
      group =>
        StravaTriggerIntegrationActivityTypesMappedByGroups[
          group as StravaTriggerIntegrationActivityGroupsType
        ]
    )
    .map(activityType => (
      <ActivityLabel key={`strava-${activityType}`}>
        {getStravaActivityTypeLabel(activityType, currentLanguage)}
      </ActivityLabel>
    ));
};

export default StravaActivityGroups;
