import type {GarminConnectBeActiveTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/beActive';
import {toKilometers, toMinutes} from '@cohort/shared/utils/convert';
import GarminConnectActivityGroups from '@cohort/wallet/apps/garmin-connect/triggerIntegrations/ActivityGroups';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {
  ActivityGroupsSection,
  DateRangeSection,
  MetricSection,
} from '@cohort/wallet/components/challenges/FitnessDetailComponents';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const GarminConnectBeActiveTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<GarminConnectBeActiveTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'challenges.fitnessDetailComponents',
  });

  const startDate = new Date(config.startDateTimestamp);
  const endDate = new Date(config.endDateTimestamp);
  const unit = match(config.metric)
    .with('distance', () => 'km')
    .with('duration', () => 'min')
    .with('elevation', () => 'm')
    .exhaustive();

  const metricTitle = match(config.metric)
    .with('distance', () => t('distanceCumulativeTitle'))
    .with('duration', () => t('durationCumulativeTitle'))
    .with('elevation', () => t('elevationCumulativeTitle'))
    .exhaustive();

  const value = match(config.metric)
    .with('distance', () => toKilometers(config.value))
    .with('duration', () => toMinutes(config.value))
    .with('elevation', () => config.value)
    .exhaustive();

  return (
    <div className="flex flex-col gap-4">
      <ActivityGroupsSection>
        {config.activityGroups && (
          <GarminConnectActivityGroups activityGroups={config.activityGroups} />
        )}
      </ActivityGroupsSection>
      <DateRangeSection startDate={startDate} endDate={endDate} />
      <MetricSection metricTitle={metricTitle} value={value} unit={unit} />
    </div>
  );
};

export default GarminConnectBeActiveTriggerIntegrationDetailComponent;
