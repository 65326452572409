import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';
import {CohortLinkMediaSpec} from '@cohort/shared/apps/cohort/medias/link';
import CohortLinkDisplayComponent from '@cohort/wallet/apps/cohort/medias/link/DisplayComponent';
import CohortLinkVisualPreview from '@cohort/wallet/apps/cohort/medias/link/VisualPreview';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {Link} from '@phosphor-icons/react';

export const CohortLinkContentMedia: ContentMedia<CohortLinkMediaStruct> = {
  spec: CohortLinkMediaSpec,
  getTitle: t => t('medias.link.title', {ns: 'app-cohort'}),
  displayComponent: CohortLinkDisplayComponent,
  icon: ({size, textColor}) => <Link size={size} className={textColor} />,
  visualPreview: CohortLinkVisualPreview,
};
