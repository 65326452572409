import type {BadgeWDto} from '@cohort/wallet-schemas/badge';
import type {CampaignChallengeWDto} from '@cohort/wallet-schemas/campaign';
import type {OwnershipWDto} from '@cohort/wallet-schemas/ownership';
import type {PerkSpaceViewWDto} from '@cohort/wallet-schemas/perkAccess';
import {createContext} from 'react';

export interface SpaceContextType {
  badges: Array<BadgeWDto>;
  challenges: Array<CampaignChallengeWDto>;
  ownerships: Array<OwnershipWDto>;
  perks: Array<PerkSpaceViewWDto>;
}

const SpaceContext = createContext<SpaceContextType | undefined>(undefined);

export default SpaceContext;
