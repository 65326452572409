import type {TypeformFillFormTriggerStruct} from '@cohort/shared/apps/typeform/triggers/fillForm';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import TypeformWidget from '@cohort/wallet/apps/typeform/triggerIntegrations/TypeformWidget';

const TypeformFillFormUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<TypeformFillFormTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config}) => (
  <TypeformWidget formId={config.formId} onSubmit={onTriggerIntegrationUsageSuccess} />
);

export default TypeformFillFormUsageComponent;
