import type {YoutubeVideoMediaStruct} from '@cohort/shared/apps/youtube/medias/video';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {contentVisualPreviewImageElement} from '@cohort/wallet/apps/utils/contents';

const YoutubeVideoVisualPreview: React.FC<VisualPreviewProps<YoutubeVideoMediaStruct>> = ({
  config,
  title,
}) => {
  // Youtube standard thumbnail url based on the video ID (https://stackoverflow.com/a/2068371)
  const thumbnailSrc = `https://img.youtube.com/vi/${config.videoId}/sddefault.jpg`;

  return contentVisualPreviewImageElement(title, thumbnailSrc);
};

export default YoutubeVideoVisualPreview;
