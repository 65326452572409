import type {CohortFormAppStruct} from '@cohort/shared/apps/cohort-form';
import {CohortFormAppSpec} from '@cohort/shared/apps/cohort-form';
import type {App} from '@cohort/wallet/apps';
import {CohortFormPerkIntegration} from '@cohort/wallet/apps/cohort-form/perkIntegrations/fillForm/PerkIntegration';
import {CohortFillFormTriggerIntegration} from '@cohort/wallet/apps/cohort-form/triggerIntegrations/fillForm/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const CohortFormApp: App<CohortFormAppStruct> = {
  medias: [],
  spec: CohortFormAppSpec,
  perkIntegrations: [CohortFormPerkIntegration],
  triggerIntegrations: [CohortFillFormTriggerIntegration] as TriggerIntegration[],
  logo: null,
};

export default CohortFormApp;
