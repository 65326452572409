import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import {FacebookLikePageTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePage';
import FacebookLikePageTriggerActionCtaComponent from '@cohort/wallet/apps/facebook/triggers/likePage/ActionCtaComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookLikePageTriggerIntegration: TriggerIntegration<FacebookLikePageTriggerStruct> =
  {
    type: 'action',
    spec: FacebookLikePageTriggerSpec,
    actionCtaComponent: FacebookLikePageTriggerActionCtaComponent,
  };
