import MediaContentCohortLink from '@cohort/components-xps/components/contents/apps/cohort/MediaContentCohortLink';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {CohortLinkMediaStruct} from '@cohort/shared/apps/cohort/medias/link';

const CohortLinkDisplayComponent: React.FC<MediaContentProps<CohortLinkMediaStruct>> = ({
  description,
  config,
  title,
}) => {
  return <MediaContentCohortLink config={config} title={title} description={description} />;
};

export default CohortLinkDisplayComponent;
