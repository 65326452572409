import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {BaseModalProps} from '@cohort/wallet/components/modals/BaseModal';
import usePageViewTracker from '@cohort/wallet/hooks/pageViewTracker';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {Drawer} from 'vaul';

export type DrawerModalProps = BaseModalProps & {
  height?: 'full' | 'fit-content';
};

const DrawerModal = ({
  title,
  subtitle,
  onClose,
  children,
  tracking,
  height = 'fit-content',
}: DrawerModalProps): JSX.Element => {
  const {backgroundColor} = useThemeContext();

  usePageViewTracker(tracking.namespace, tracking.metadata);

  return (
    <Drawer.Root open={true} onClose={onClose} dismissible={!!onClose}>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-50 bg-black/40" />
        <Drawer.Content
          className={cn(
            'fixed bottom-0 left-0 right-0 z-50 flex max-h-[96vh] flex-col gap-4 rounded-t-[var(--xps-modal-border-radius)] px-6 pt-4 *:px-2',
            height === 'full' && 'h-[96vh]'
          )}
          style={{backgroundColor: backgroundColor}}
        >
          <div className="flex flex-col gap-2">
            <div className="mx-auto h-1.5 w-12 flex-shrink-0 rounded-full bg-zinc-300" />
            {title}
            {subtitle}
          </div>
          <div className="no-scrollbar flex-grow overflow-y-auto pb-8">{children}</div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default DrawerModal;
