import type {GarminConnectCompleteActivityTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import {GarminConnectCompleteActivityTriggerIntegrationSpec} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import GarminConnectCompleteActivityTriggerIntegrationDetailComponent from '@cohort/wallet/apps/garmin-connect/triggerIntegrations/completeActivity/DetailComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const GarminConnectCompleteActivityTriggerIntegration: TriggerIntegration<GarminConnectCompleteActivityTriggerStruct> =
  {
    type: 'verification',
    spec: GarminConnectCompleteActivityTriggerIntegrationSpec,
    detailsComponent: GarminConnectCompleteActivityTriggerIntegrationDetailComponent,
    disableExponentialBackoff: true,
  };
