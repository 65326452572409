import type {GarminConnectCompleteActivityTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import GarminConnectActivityGroups from '@cohort/wallet/apps/garmin-connect/triggerIntegrations/ActivityGroups';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {FitnessCompleteActivityTriggerIntegrationDetailComponent} from '@cohort/wallet/components/challenges/FitnessDetailComponents';

const GarminConnectCompleteActivityTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<GarminConnectCompleteActivityTriggerStruct>
> = ({config}) => (
  <FitnessCompleteActivityTriggerIntegrationDetailComponent
    ActivityGroupsComponent={GarminConnectActivityGroups}
    config={config}
  />
);

export default GarminConnectCompleteActivityTriggerIntegrationDetailComponent;
