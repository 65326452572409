import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';
import {CohortVideoMediaSpec} from '@cohort/shared/apps/cohort/medias/video';
import CohortVideoDisplayComponent from '@cohort/wallet/apps/cohort/medias/video/DisplayComponent';
import CohortVideoVisualPreview from '@cohort/wallet/apps/cohort/medias/video/VisualPreview';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {Video} from '@phosphor-icons/react';

export const CohortVideoContentMedia: ContentMedia<CohortVideoMediaStruct> = {
  spec: CohortVideoMediaSpec,
  getTitle: t => t('medias.video.title', {ns: 'app-cohort'}),
  displayComponent: CohortVideoDisplayComponent,
  icon: ({size, textColor}) => <Video size={size} className={textColor} />,
  visualPreview: CohortVideoVisualPreview,
};
