import type {CohortAppStruct} from '@cohort/shared/apps/cohort';
import {CohortAppSpec} from '@cohort/shared/apps/cohort';
import type {App} from '@cohort/wallet/apps';
import {CohortArticleContentMedia} from '@cohort/wallet/apps/cohort/medias/article/Media';
import {CohortImageContentMedia} from '@cohort/wallet/apps/cohort/medias/image/Media';
import {CohortLinkContentMedia} from '@cohort/wallet/apps/cohort/medias/link/Media';
import {CohortVideoContentMedia} from '@cohort/wallet/apps/cohort/medias/video/Media';
import {CohortQrCodePerkIntegration} from '@cohort/wallet/apps/cohort/perkIntegrations/qrCode/PerkIntegration';
import {CohortBeInCohortTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/beInCohort/TriggerIntegration';
import {CohortDigitalAssetOwnedTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/digitalAssetOwned/TriggerIntegration';
import {CohortPerkUsedTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/perkUsed/TriggerIntegration';
import {CohortScanQrCodeTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/scanQrCode/TriggerIntegration';
import {CohortUserPropertyConditionMetTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/userPropertyConditionMet/TriggerIntegration';
import {CohortVisitLinkTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/visitLink/TriggerIntegration';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const CohortApp: App<CohortAppStruct> = {
  spec: CohortAppSpec,
  perkIntegrations: [CohortQrCodePerkIntegration],
  triggerIntegrations: [
    CohortBeInCohortTriggerIntegration,
    CohortDigitalAssetOwnedTriggerIntegration,
    CohortPerkUsedTriggerIntegration,
    CohortScanQrCodeTriggerIntegration,
    CohortVisitLinkTriggerIntegration,
    CohortUserPropertyConditionMetTriggerIntegration,
  ] as TriggerIntegration[],
  medias: [
    CohortArticleContentMedia,
    CohortImageContentMedia,
    CohortLinkContentMedia,
    CohortVideoContentMedia,
  ] as Array<ContentMedia>,
  logo: null,
};

export default CohortApp;
