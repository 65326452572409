import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@cohort/shared-frontend/components/Dropdown';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import Button from '@cohort/wallet/components/button/Button';
import ManageDataModal from '@cohort/wallet/components/modals/auth/ManageDataModal';
import TrackableButton from '@cohort/wallet/components/tracking/TrackableButton';
import {campaignsKeys} from '@cohort/wallet/hooks/api/Campaign';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import useChallengeContext from '@cohort/wallet/hooks/useChallengeContext';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {logoutUser} from '@cohort/wallet/lib/Authentication';
import {getChallengeLoginRoute} from '@cohort/wallet/lib/Pages';
import type {UserWDto} from '@cohort/wallet-schemas/user';
import {Database, UserCircle} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const ChallengeDropdownTrigger: React.FC = () => (
  <DropdownMenuTrigger>
    <TrackableButton
      tracking={{namespace: 'navbar.profile.toggle'}}
      className="relative inline-flex cursor-pointer items-center rounded-md border-transparent px-3 py-2 text-sm font-medium text-[--xps-navbar-link-color] hover:bg-[--xps-navbar-link-active-background-color] hover:text-[--xps-color]"
    >
      <UserCircle className="h-5 w-5" aria-hidden="true" />
    </TrackableButton>
  </DropdownMenuTrigger>
);

type DropdownUserSectionProps = {
  user: UserWDto;
};

const ChallengeDropdownUserSection: React.FC<DropdownUserSectionProps> = ({user}) => {
  const {t} = useTranslation('layouts', {keyPrefix: 'challenges.challengeDropdownNavigation'});
  const {campaign} = useChallengeContext();
  const merchant = useMerchantContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const disableLogout = useAppStore(store => store.disableLogout);

  return (
    <DropdownMenuGroup className="mb-1 space-y-3 border-b p-4 text-sm font-normal">
      <Trans
        i18nKey="challenges.challengeDropdownNavigation.helloUser"
        ns="layouts"
        values={{email: user.email}}
        className="px-4 py-2 text-sm"
        components={{
          bold: <span className="font-medium" />,
        }}
      />
      {!disableLogout && (
        <div className="flex flex-col space-y-2">
          <p>{t('notYou')}</p>
          <Button
            variant="secondary"
            size="small"
            onClick={async () => {
              await logoutUser();
              await queryClient.invalidateQueries(
                campaignsKeys.getChallengeBySlug(merchant.id, campaign.challenge.slug)
              );
              navigate(getChallengeLoginRoute(campaign.challenge.slug));
            }}
            tracking={{
              namespace: 'navbar.profile.logout',
            }}
          >
            {t('switchEmail')}
          </Button>
        </div>
      )}
    </DropdownMenuGroup>
  );
};

type ChallengeDropdownButtonsSectionProps = {
  openManageDataModal: () => void;
};

const ChallengeDropdownButtonsSection: React.FC<ChallengeDropdownButtonsSectionProps> = ({
  openManageDataModal,
}) => {
  const {t} = useTranslation('layouts', {keyPrefix: 'challenges.challengeDropdownNavigation'});
  const {hasDarkBg} = useThemeContext();

  return (
    <DropdownMenuGroup className="my-2 flex flex-col">
      <DropdownMenuItem>
        <button className="flex items-center space-x-2 no-underline" onClick={openManageDataModal}>
          <Database
            className={cn('mr-2 h-5 w-5', hasDarkBg ? 'text-white/30' : 'text-black/30')}
            aria-hidden="true"
          />
          <p>{t('manageData')}</p>
        </button>
      </DropdownMenuItem>
    </DropdownMenuGroup>
  );
};

const ChallengeDropdownNavigation: React.FC = () => {
  const user = useUserStore(store => store.user);
  const {backgroundColor} = useThemeContext();
  const [manageDataModalOpened, setManageDataModalOpened] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  if (!user) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <ChallengeDropdownTrigger />
        <DropdownMenuContent style={{backgroundColor}} align="end">
          <ChallengeDropdownUserSection user={user} />
          <ChallengeDropdownButtonsSection
            openManageDataModal={() => {
              // the dropdown needs to be closed before opening the drawer, otherwise background conflicts occur
              setOpen(false);
              setManageDataModalOpened(true);
            }}
          />
        </DropdownMenuContent>
      </DropdownMenu>
      {manageDataModalOpened && <ManageDataModal onClose={() => setManageDataModalOpened(false)} />}
    </Fragment>
  );
};
export default ChallengeDropdownNavigation;
