import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TikTokCommentTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import serializeDataAttributes from '@cohort/shared-frontend/utils/serializeDataAtttributes';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';

const TiktokCommentTiktokTriggerIntegrationDetailsComponent: React.FC<
  DetailsComponentProps<TikTokCommentTiktokTriggerStruct>
> = ({config, stepId, triggerId}) => (
  <div
    className="flex items-center justify-center"
    {...serializeDataAttributes({
      'step-id': stepId,
      'trigger-id': triggerId,
    })}
  >
    <TiktokEmbedPost videoId={config.videoId} />
  </div>
);

export default TiktokCommentTiktokTriggerIntegrationDetailsComponent;
