import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {FacebookCommentPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/commentPost';
import FacebookCommentPostTriggerActionCtaComponent from '@cohort/wallet/apps/facebook/triggers/commentPost/ActionCtaComponent';
import FacebookCommentPostTriggerIntegrationDetailsComponent from '@cohort/wallet/apps/facebook/triggers/commentPost/DetailsComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const FacebookCommentPostTriggerIntegration: TriggerIntegration<FacebookCommentPostTriggerStruct> =
  {
    type: 'action',
    spec: FacebookCommentPostTriggerSpec,
    detailsComponent: FacebookCommentPostTriggerIntegrationDetailsComponent,
    actionCtaComponent: FacebookCommentPostTriggerActionCtaComponent,
  };
