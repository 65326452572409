// i18nOwl-ignore [app-google-fit.userDataPolicy]
import type {GoogleFitAppStruct} from '@cohort/shared/apps/google-fit';
import {GoogleFitAppSpec} from '@cohort/shared/apps/google-fit';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {GoogleFitBeActiveTriggerIntegration} from '@cohort/wallet/apps/google-fit/triggerIntegrations/beActive/TriggerIntegration';
import {GoogleFitCompleteActivityTriggerIntegration} from '@cohort/wallet/apps/google-fit/triggerIntegrations/completeActivity/TriggerIntegration';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const GoogleFitApp: App<GoogleFitAppStruct> = {
  spec: GoogleFitAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    GoogleFitBeActiveTriggerIntegration,
    GoogleFitCompleteActivityTriggerIntegration,
  ] as Array<TriggerIntegration>,
  medias: [] as Array<ContentMedia>,
  logo: <SvgAppIcon name="google-fit" height={40} width={40} />,
  withUserDataPolicy: true,
};

export default GoogleFitApp;
