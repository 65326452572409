import type {TalonOneAppStruct} from '@cohort/shared/apps/talon-one';
import {TalonOneAppSpec} from '@cohort/shared/apps/talon-one';
import type {App} from '@cohort/wallet/apps';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {TalonOneCouponPerkIntegration} from '@cohort/wallet/apps/talon-one/perkIntegrations/coupon/PerkIntegration';

const TalonOneApp: App<TalonOneAppStruct> = {
  spec: TalonOneAppSpec,
  perkIntegrations: [TalonOneCouponPerkIntegration],
  triggerIntegrations: [],
  medias: [] as Array<ContentMedia>,
  logo: null,
};

export default TalonOneApp;
