import type {GoogleFitBeActiveTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {GoogleFitBeActiveTriggerIntegrationSpec} from '@cohort/shared/apps/google-fit/triggers/beActive';
import GoogleFitBeActiveTriggerIntegrationDetailComponent from '@cohort/wallet/apps/google-fit/triggerIntegrations/beActive/DetailComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const GoogleFitBeActiveTriggerIntegration: TriggerIntegration<GoogleFitBeActiveTriggerStruct> =
  {
    type: 'verification',
    spec: GoogleFitBeActiveTriggerIntegrationSpec,
    detailsComponent: GoogleFitBeActiveTriggerIntegrationDetailComponent,
    disableExponentialBackoff: true,
  };
