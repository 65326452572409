import {useEffect, useState} from 'react';

type CameraPermission = PermissionState | 'unknown';

const useCameraPermission = (): CameraPermission => {
  const [cameraPermission, setCameraPermission] = useState<CameraPermission>('unknown');

  useEffect(() => {
    async function checkCameraPermission(): Promise<void> {
      try {
        // @ts-expect-error camera is not in permissions list since it's not supported in Firefox
        const status = await navigator.permissions.query({name: 'camera'});

        status.onchange = () => {
          setCameraPermission(status.state);
        };
        return setCameraPermission(status.state);
      } catch {
        try {
          // Handle browsers that don't support the Permissions API like Firefox
          await navigator.mediaDevices.getUserMedia({video: true});

          setCameraPermission('granted');
        } catch {
          setCameraPermission('denied');
        }
      }
    }
    checkCameraPermission();
  }, []);

  return cameraPermission;
};

export default useCameraPermission;
