import type {GoogleFitBeActiveTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/beActive';
import {toKilometers} from '@cohort/shared/utils/convert';
import GoogleFitActivityGroups from '@cohort/wallet/apps/google-fit/triggerIntegrations/ActivityGroups';
import type {DetailsComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {
  ActivityGroupsSection,
  DateRangeSection,
  MetricSection,
} from '@cohort/wallet/components/challenges/FitnessDetailComponents';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const GoogleFitBeActiveTriggerIntegrationDetailComponent: React.FC<
  DetailsComponentProps<GoogleFitBeActiveTriggerStruct>
> = ({config}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'challenges.fitnessDetailComponents',
  });

  const startDate = new Date(config.startDateTimestamp);
  const endDate = new Date(config.endDateTimestamp);
  const unit = match(config.metric)
    .with('active minutes', () => 'min')
    .with('distance', () => 'km')
    .with('heart points', () => '')
    .with('steps', () => '')
    .exhaustive();

  const metricTitle = match(config.metric)
    .with('active minutes', () => t('durationCumulativeTitle'))
    .with('distance', () => t('distanceCumulativeTitle'))
    .with('heart points', () => t('heartPointsCumulativeTitle'))
    .with('steps', () => t('numberOfStepsTitle'))
    .exhaustive();

  const value = match(config.metric)
    .with('distance', () => toKilometers(config.value))
    .otherwise(() => config.value);

  return (
    <div className="flex flex-col gap-4">
      <ActivityGroupsSection>
        {config.activityGroups && (
          <GoogleFitActivityGroups activityGroups={config.activityGroups} />
        )}
      </ActivityGroupsSection>
      <DateRangeSection startDate={startDate} endDate={endDate} />
      <MetricSection metricTitle={metricTitle} value={value} unit={unit} />
    </div>
  );
};

export default GoogleFitBeActiveTriggerIntegrationDetailComponent;
