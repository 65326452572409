import type {YoutubeSubscribeTriggerStruct} from '@cohort/shared/apps/youtube/triggers/subscribe';
import {YoutubeSubscribeTriggerSpec} from '@cohort/shared/apps/youtube/triggers/subscribe';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import YoutubeSubscribeTriggerActionCtaComponent from '@cohort/wallet/apps/youtube/triggers/subscribe/ActionCtaComponent';

export const YoutubeSubscribeTriggerIntegration: TriggerIntegration<YoutubeSubscribeTriggerStruct> =
  {
    type: 'action',
    spec: YoutubeSubscribeTriggerSpec,
    actionCtaComponent: YoutubeSubscribeTriggerActionCtaComponent,
  };
