import type {YoutubeVideoMediaStruct} from '@cohort/shared/apps/youtube/medias/video';
import {YoutubeVideoMediaSpec} from '@cohort/shared/apps/youtube/medias/video';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import YoutubeVideoDisplayComponent from '@cohort/wallet/apps/youtube/medias/video/DisplayComponent';
import YoutubeVideoVisualPreview from '@cohort/wallet/apps/youtube/medias/video/VisualPreview';

export const YoutubeVideoContentMedia: ContentMedia<YoutubeVideoMediaStruct> = {
  spec: YoutubeVideoMediaSpec,
  getTitle: t => t('medias.video.title', {ns: 'app-youtube'}),
  displayComponent: YoutubeVideoDisplayComponent,
  icon: ({size}) => <SvgAppIcon name="youtube" height={size} width={size} />,
  visualPreview: YoutubeVideoVisualPreview,
};
