import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import CohortFormComponent from '@cohort/wallet/apps/cohort-form/CohortFormComponent';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import {useIsMutating} from '@tanstack/react-query';

const CohortFillFormUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFillFormTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId}) => {
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const isVerifying = ongoingVerifications > 0;

  return (
    <div className="embedded group rounded-xl bg-[--xps-card-background-color] p-4">
      <CohortFormComponent
        config={config}
        onSubmit={data => onTriggerIntegrationUsageSuccess({answers: data})}
        isLoading={isVerifying}
        onSubmitTracking={{
          namespace: 'trigger.completion',
          metadata: {
            appId: 'cohort',
            action: 'trigger.fillForm',
          },
        }}
      />
    </div>
  );
};

export default CohortFillFormUsageComponent;
