import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import type {InputProps} from '@cohort/wallet/components/forms/Input';
import React from 'react';

type DefaultInput = Omit<JSX.IntrinsicElements['input'], 'type'>;

export const CheckboxInput: React.FC<DefaultInput & InputProps> = ({
  testId,
  register,
  label,
  mandatory,
  error,
}) => (
  <FieldWrapper label={label} mandatory={mandatory} error={error} labelPosition="right">
    <input
      data-testid={testId}
      type="checkbox"
      {...register}
      className="h-4 w-4 cursor-pointer rounded border border-[--xps-input-border-color] bg-[--xps-input-background-color] text-[--xps-accent-color] focus:outline-none focus:ring-0 focus:ring-offset-0"
    />
  </FieldWrapper>
);
