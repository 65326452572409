import Button from '@cohort/wallet/components/button/Button';
import type {TriggerCtaTrackingMetadata} from '@cohort/wallet/components/challenges/TriggerCta';

type OpenCtaUnverifiedProps = {
  url: string;
  ctaTitle: string;
  processStepVerification: () => void;
  trackingMetadata: TriggerCtaTrackingMetadata;
};

const OpenCtaUnverified: React.FC<OpenCtaUnverifiedProps> = ({
  url,
  ctaTitle,
  processStepVerification,
  trackingMetadata,
}) => (
  <a href={url} target="_blank" className="block w-full">
    <Button
      variant="primary"
      size="small"
      className="w-full"
      type="button"
      tracking={{
        namespace: 'challenges.detail.actionCtaUnverified',
        metadata: trackingMetadata,
      }}
      onClick={() => processStepVerification()}
    >
      {ctaTitle}
    </Button>
  </a>
);

export default OpenCtaUnverified;
