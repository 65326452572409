import type {GarminConnectCompleteActivityTriggerStruct} from '@cohort/shared/apps/garmin-connect/triggers/completeActivity';
import type {GoogleFitCompleteActivityTriggerStruct} from '@cohort/shared/apps/google-fit/triggers/completeActivity';
import type {StravaCompleteActivityTriggerStruct} from '@cohort/shared/apps/strava/triggers/completeActivity';
import {toKilometers, toKilometersPerHour, toMinutes} from '@cohort/shared/utils/convert';
import DetailComponentSection from '@cohort/wallet/components/challenges/DetailsComponentSection';
import {toUserDate} from '@cohort/wallet/lib/Utils';
import {useTranslation} from 'react-i18next';
type CompleteActivityTriggerStruct =
  | StravaCompleteActivityTriggerStruct
  | GoogleFitCompleteActivityTriggerStruct
  | GarminConnectCompleteActivityTriggerStruct;

type FitnessCompleteActivityTriggerIntegrationDetailComponentProps<
  T extends CompleteActivityTriggerStruct,
> = {
  config: T['Config'];
  ActivityGroupsComponent: React.FC<{activityGroups: string[]}>;
};

type DateRangeSectionProps = {
  startDate: Date;
  endDate: Date;
};

type MetricSectionProps = {
  metricTitle: string;
  value: number;
  unit: string;
};

const ActivityLabel: React.FC<{children: React.ReactNode}> = ({children}) => (
  <div className="inline-flex items-center whitespace-nowrap rounded-md bg-[--xps-card-background-color] px-2.5 py-0.5 text-sm font-medium">
    {children}
  </div>
);

const ActivityGroupsSection: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'challenges.fitnessDetailComponents',
  });

  return (
    <DetailComponentSection title={t('eligibleActivitiesTitle')}>
      <div className="flex flex-row flex-wrap gap-2">
        {children ? children : <p>{t('eligibleActivitiesIsNullContent')}</p>}
      </div>
    </DetailComponentSection>
  );
};

const DateRangeSection: React.FC<DateRangeSectionProps> = ({startDate, endDate}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'challenges.fitnessDetailComponents',
  });

  return (
    <DetailComponentSection title={t('dateRangeTitle')}>
      <div className="flex flex-row gap-1">
        <p>{t('startDateLabel')}</p>
        <p>{toUserDate(startDate)}</p>
        <p>{t('endDateLabel')}</p>
        <p>{toUserDate(endDate)}</p>
      </div>
    </DetailComponentSection>
  );
};

const MetricSection: React.FC<MetricSectionProps> = ({metricTitle, value, unit}) => (
  <DetailComponentSection title={metricTitle}>
    <p>
      {value} {unit}
    </p>
  </DetailComponentSection>
);

function FitnessCompleteActivityTriggerIntegrationDetailComponent<
  T extends CompleteActivityTriggerStruct,
>({
  config,
  ActivityGroupsComponent,
}: FitnessCompleteActivityTriggerIntegrationDetailComponentProps<T>): React.ReactElement {
  const {t} = useTranslation('components', {
    keyPrefix: 'challenges.fitnessDetailComponents',
  });

  const startDate = new Date(config.activityStartDateTimestamp);
  const endDate = new Date(config.activityEndDateTimestamp);

  return (
    <div className="flex flex-col gap-4">
      <ActivityGroupsSection>
        {config.activityGroups && (
          <ActivityGroupsComponent activityGroups={config.activityGroups} />
        )}
      </ActivityGroupsSection>

      <DateRangeSection startDate={startDate} endDate={endDate} />

      {config.minDistanceMeters && (
        <DetailComponentSection title={t('distanceTitle')}>
          <p>{toKilometers(config.minDistanceMeters)} km</p>
        </DetailComponentSection>
      )}

      {config.minDurationSeconds && (
        <DetailComponentSection title={t('durationTitle')}>
          <p>{toMinutes(config.minDurationSeconds)} minutes</p>
        </DetailComponentSection>
      )}

      {config.minAverageElevationGainMeters && (
        <DetailComponentSection title={t('elevationTitle')}>
          <p>{config.minAverageElevationGainMeters} m</p>
        </DetailComponentSection>
      )}

      {config.minAverageSpeedMetersPerSecond && (
        <DetailComponentSection title={t('speedTitle')}>
          <p>{toKilometersPerHour(config.minAverageSpeedMetersPerSecond)} km/h</p>
        </DetailComponentSection>
      )}
    </div>
  );
}

export {
  ActivityLabel,
  FitnessCompleteActivityTriggerIntegrationDetailComponent,
  ActivityGroupsSection,
  DateRangeSection,
  MetricSection,
};
