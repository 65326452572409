import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {ChallengeStepOrchestrationSchema} from '@cohort/shared/schema/common/challengeStep';
import {ConnectionWSchema} from '@cohort/wallet-schemas/connections';
import {z} from 'zod';

export const ChallengeStepTriggerWSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string().nullable(),
  triggerIntegrationId: TriggerIntegrationIdSchema,
  triggerIntegrationConfig: z.unknown(),
  connectionId: z.string().uuid().nullable(),
  verificationAttemptsLeft: z.number().nullable(),
  connection: ConnectionWSchema.nullable(),
});
export type ChallengeStepTriggerWDto = z.infer<typeof ChallengeStepTriggerWSchema>;

const ChallengeStepAvailabilitySchema = z.union([
  z.object({locked: z.literal(false)}),
  z.object({
    locked: z.literal(true),
    reason: z.union([z.literal('start-date'), z.literal('pre-required-steps')]),
  }),
]);

export const ChallengeStepWSchema = z.object({
  id: z.string().uuid(),
  triggers: ChallengeStepTriggerWSchema.array(),
  title: z.string(),
  description: z.string(),
  icon: z.string().emoji(),
  isCompleted: z.boolean(),
  availability: ChallengeStepAvailabilitySchema,
  orchestration: ChallengeStepOrchestrationSchema,
});
export type ChallengeStepWDto = z.infer<typeof ChallengeStepWSchema>;
