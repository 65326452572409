import type {FacebookAppStruct} from '@cohort/shared/apps/facebook';
import {FacebookAppSpec} from '@cohort/shared/apps/facebook';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {FacebookCommentPostTriggerIntegration} from '@cohort/wallet/apps/facebook/triggers/commentPost/TriggerIntegration';
import {FacebookLikePageTriggerIntegration} from '@cohort/wallet/apps/facebook/triggers/likePage/TriggerIntegration';
import {FacebookLikePageUnverifiedTriggerIntegration} from '@cohort/wallet/apps/facebook/triggers/likePageUnverified/TriggerIntegration';
import {FacebookLikePostTriggerIntegration} from '@cohort/wallet/apps/facebook/triggers/likePost/TriggerIntegration';
import {FacebookTagInPostTriggerIntegration} from '@cohort/wallet/apps/facebook/triggers/tagInPost/TriggerIntegration';
import {FacebookTagInPostUnverifiedTriggerIntegration} from '@cohort/wallet/apps/facebook/triggers/tagInPostUnverified/TriggerIntegration';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const FacebookApp: App<FacebookAppStruct> = {
  spec: FacebookAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    FacebookLikePageTriggerIntegration,
    FacebookLikePageUnverifiedTriggerIntegration,
    FacebookTagInPostTriggerIntegration,
    FacebookTagInPostUnverifiedTriggerIntegration,
    FacebookCommentPostTriggerIntegration,
    FacebookLikePostTriggerIntegration,
  ] as Array<TriggerIntegration>,
  medias: [] as Array<ContentMedia>,
  logo: <SvgAppIcon name="facebook" height={40} width={40} />,
};

export default FacebookApp;
