import type {TwitterFollowTriggerStruct} from '@cohort/shared/apps/twitter/triggers/follow';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaWithRefresh from '@cohort/wallet/components/challenges/OpenCtaWithRefresh';
import {useTranslation} from 'react-i18next';

const TwitterFollowTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<TwitterFollowTriggerStruct>
> = ({config, trackingMetadata, stepId, processStepVerification}) => {
  const {t} = useTranslation('app-twitter', {
    keyPrefix: 'triggerIntegrations.follow',
  });

  return (
    <OpenCtaWithRefresh
      url={config.profileUrl}
      stepId={stepId}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
      ctaTitle={t('ctaTitle')}
      refreshCtaTitle={t('refreshTitle')}
    />
  );
};

export default TwitterFollowTriggerActionCtaComponent;
