import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaWithRefresh from '@cohort/wallet/components/challenges/OpenCtaWithRefresh';
import {useTranslation} from 'react-i18next';

const FacebookCommentPostTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<FacebookCommentPostTriggerStruct>
> = ({config, trackingMetadata, stepId, processStepVerification}) => {
  const {t} = useTranslation('app-facebook', {
    keyPrefix: 'triggerIntegrations.comment-post',
  });

  return (
    <OpenCtaWithRefresh
      url={config.postPermalink}
      stepId={stepId}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
      ctaTitle={t('ctaTitle')}
      refreshCtaTitle={t('refreshTitle')}
    />
  );
};

export default FacebookCommentPostTriggerActionCtaComponent;
