/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {useApi} from '@cohort/wallet/hooks/useApi';
import {ContentsApi} from '@cohort/wallet/lib/Endpoints';

export const contentsKeys = {
  contents: ['contents'] as const,
  getContentsPreviewByCategories: () =>
    [...contentsKeys.contents, 'contentsPreviewByCategories'] as const,
};

export const useContentsPreviewByCategories = (enabled?: boolean) => {
  const getContentsPreviewByCategories = useApi(ContentsApi.getContentsPreviewByCategories);
  return useCohortQuery({
    queryKey: contentsKeys.getContentsPreviewByCategories(),
    queryFn: async () => getContentsPreviewByCategories(),
    enabled,
  });
};
