import type {ShopifyAppStruct} from '@cohort/shared/apps/shopify';
import {ShopifyAppSpec} from '@cohort/shared/apps/shopify';
import type {App} from '@cohort/wallet/apps';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {ShopifyDiscountPerkIntegration} from '@cohort/wallet/apps/shopify/perkIntegrations/discount/PerkIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const ShopifyApp: App<ShopifyAppStruct> = {
  spec: ShopifyAppSpec,
  perkIntegrations: [ShopifyDiscountPerkIntegration],
  triggerIntegrations: [] as Array<TriggerIntegration>,
  medias: [] as Array<ContentMedia>,
  logo: null,
};

export default ShopifyApp;
