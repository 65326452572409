import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import type {ActionCtaComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import OpenCtaUnverified from '@cohort/wallet/components/challenges/OpenCtaUnverified';
import {useTranslation} from 'react-i18next';

const TikTokLikeTiktokTriggerActionCtaComponent: React.FC<
  ActionCtaComponentProps<TikTokLikeTiktokTriggerStruct>
> = ({config, processStepVerification, trackingMetadata}) => {
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.like-tiktok',
  });

  return (
    <OpenCtaUnverified
      url={config.url}
      ctaTitle={t('ctaTitle')}
      processStepVerification={processStepVerification}
      trackingMetadata={trackingMetadata}
    />
  );
};

export default TikTokLikeTiktokTriggerActionCtaComponent;
