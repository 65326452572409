type DetailComponentSectionProps = {
  title: string;
  children: React.ReactElement | React.ReactElement[];
};

const DetailComponentSection: React.FC<DetailComponentSectionProps> = ({title, children}) => (
  <div className="flex flex-col gap-2">
    <h3 className="text-sm">{title}</h3>
    {children}
  </div>
);

export default DetailComponentSection;
