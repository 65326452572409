import type {SpotifyPlaylistMediaStruct} from '@cohort/shared/apps/spotify/medias/playlist';
import {SpotifyPlaylistMediaSpec} from '@cohort/shared/apps/spotify/medias/playlist';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import SpotifyPlaylistDisplayComponent from '@cohort/wallet/apps/spotify/medias/playlist/DisplayComponent';
import SpotifyPlaylistVisualPreview from '@cohort/wallet/apps/spotify/medias/playlist/VisualPreview';

export const SpotifyPlaylistContentMedia: ContentMedia<SpotifyPlaylistMediaStruct> = {
  spec: SpotifyPlaylistMediaSpec,
  getTitle: t => t('medias.playlist.title', {ns: 'app-spotify'}),
  displayComponent: SpotifyPlaylistDisplayComponent,
  icon: ({size}) => <SvgAppIcon name="spotify" height={size} width={size} />,
  visualPreview: SpotifyPlaylistVisualPreview,
};
