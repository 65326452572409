import React from 'react';

export const contentVisualPreviewEmptyImageElement = (icon: React.ReactElement): JSX.Element => (
  <div className="flex aspect-video w-full items-center justify-center rounded-[--xps-img-border-radius] bg-[--xps-card-background-color]">
    {React.cloneElement(icon, {size: 80, className: 'text-[--xps-border-color]'})}
  </div>
);

export const contentVisualPreviewImageElement = (title: string, imageSrc: string): JSX.Element => {
  return (
    <img
      title={title}
      className="aspect-video w-full rounded-[--xps-img-border-radius] object-cover"
      draggable="false"
      src={imageSrc}
    />
  );
};
