import type {CohortArticleMediaStruct} from '@cohort/shared/apps/cohort/medias/article';
import {CohortArticleMediaSpec} from '@cohort/shared/apps/cohort/medias/article';
import CohortArticleDisplayComponent from '@cohort/wallet/apps/cohort/medias/article/DisplayComponent';
import CohortArticleVisualPreview from '@cohort/wallet/apps/cohort/medias/article/VisualPreview';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import {TextAa} from '@phosphor-icons/react';

export const CohortArticleContentMedia: ContentMedia<CohortArticleMediaStruct> = {
  spec: CohortArticleMediaSpec,
  getTitle: t => t('medias.article.title', {ns: 'app-cohort'}),
  displayComponent: CohortArticleDisplayComponent,
  icon: ({size, textColor}) => <TextAa size={size} className={textColor} />,
  visualPreview: CohortArticleVisualPreview,
};
