import i18n from '@cohort/components-xps/lib/i18n';
import type {GarminConnectTriggerIntegrationActivityGroupsType} from '@cohort/shared/apps/garmin-connect/activity';
import {
  GarminConnectTriggerIntegrationActivityTypesMappedByGroups,
  getGarminConnectActivityTypeLabel,
} from '@cohort/shared/apps/garmin-connect/activity';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {ActivityLabel} from '@cohort/wallet/components/challenges/FitnessDetailComponents';

const GarminConnectActivityGroups: React.FC<{activityGroups: string[]}> = ({activityGroups}) => {
  const currentLanguage = formatI18nLanguage(i18n.language);

  return activityGroups
    .flatMap(
      group =>
        GarminConnectTriggerIntegrationActivityTypesMappedByGroups[
          group as GarminConnectTriggerIntegrationActivityGroupsType
        ]
    )
    .map(activityType => (
      <ActivityLabel key={`garmin-connect-${activityType}`}>
        {getGarminConnectActivityTypeLabel(activityType, currentLanguage)}
      </ActivityLabel>
    ));
};

export default GarminConnectActivityGroups;
